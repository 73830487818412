export const ServiceUrl = {
  V2_LOGIN: {
    CHECK_USERNAME: "v2/login/check-username",
    CAPTCHA: "v1/captcha",
    VERIFY_CAPTCHA: "v2/login/verify-captcha",
    UPDATE_PASSWORD: "v2/login/update-pass",
  },

  LOGIN_V2: {
    CHECK: "/v2/admin/check",
    CHECK_USERNAME: "/v1/admin/login",
  },
  COURSES: {
    CATEGORY_CREATE: "v1/admin/course/category/create",
    CATEGORY_LIST: "v1/admin/course/category/list",
    CATEGORY_DELETE: "v1/admin/course/category/delete",
    CATEGORY_UPDATE: "v1/admin/course/category/update",

    LEVEL_CREATE: "v1/admin/course/level/create",
    LEVEL_LIST: "v1/admin/course/level/list",
    LEVEL_DELETE: "v1/admin/course/level/delete",
    LEVEL_UPDATE: "v1/admin/course/level/update",

    DEGREE_CREATE: "v1/admin/course/degree/create",
    DEGREE_LIST: "v1/admin/course/degree/list",
    DEGREE_DELETE: "v1/admin/course/degree/delete",
    DEGREE_UPDATE: "v1/admin/course/degree/update",
    ADD_SPECIALIZATION: "v1/admin/course/degree/add-specialization",
    SPECIALIZATION_LIST: "v1/admin/course/degree/list-specialization",
    SPECIALIZATION_UPDATE: "v1/admin/course/degree/update-specialization",
    SPECIALIZATION_DELECT: "v1/admin/course/degree/delect-specialization",

    COURSE_CREATE: "v1/admin/course/create",
    ADD_COURSE_CODE: "v1/admin/course/add-course-code",
    COURSE_CODE_LIST: "v1/admin/course/list-course-code",
    COURSE_CODE_DELETE: "v1/admin/course/delect-course-code",
    COURSE_CODE_UPDATE: "v1/admin/course/update-course-code",
    LIST_CATEGORY_BY_DEGREE_BY_COURSE: "v1/admin/course/list-degree-course",
    LIST_CATEGORY_BY_DEGREE_BY_COURSE_BY_COLLEGE:
      "v1/admin/course/list-degree-course-in-college",
    COURSE_LIST: "v1/admin/course/list",
    CHECK_COURSE_LIST: "v1/admin/course/course-list",
    COURSE_LIST_MININFO: "v1/admin/course/min-info",
    COURSE_DELETE: "v1/admin/course/delete",
    COURSE_UPDATE_BASIC: "v1/admin/course/update-basic",
    COURSE_UPDATE_CONTENT: "v1/admin/course/update-content",
    COURSE_UPDATE_SLUG: "v1/admin/course/update-slug",
  },
  COLLEGES: {
    COUNTRY_CREATE: "v1/admin/country/create",
    COUNTRY_LIST: "v1/admin/country/list",
    COUNTRY_DELETE: "v1/admin/country/delete",
    COUNTRY_UPDATE: "v1/admin/country/update",

    STATE_CREATE: "v1/admin/state/create",
    STATE_LIST: "v1/admin/state/list",
    STATE_CODE_LIST: "v1/admin/state/list-state-code",
    STATE_DELETE: "v1/admin/state/delete",
    STATE_UPDATE: "v1/admin/state/update",

    DISTRICT_CREATE: "v1/admin/district/create",
    DISTRICT_LIST: "v1/admin/district/list",
    DISTRICT_DELETE: "v1/admin/district/delete",
    DISTRICT_UPDATE: "v1/admin/district/update",

    CITY_CREATE: "v1/admin/city/create",
    CITY_LIST: "v1/admin/city/list",
    CITY_DELETE: "v1/admin/city/delete",
    CITY_UPDATE: "v1/admin/city/update",

    FILTER_CREATE: "v1/admin/filter/create",
    FILTER_CATEGORIES: "v1/admin/filter/list",
    FILTER_DELETE: "v1/admin/filter/delete",
    FILTER_UPDATE: "v1/admin/filter/update",
    FILTER_LIST_CAT: "v1/admin/filter/list-categories",

    FILTER_CREATE_OPTIONS: "v1/admin/filter/create-option",
    FILTER_CATEGORIES_OPTIONS: "v1/admin/filter/option-list",
    FILTER_DELETE_OPTIONS: "v1/admin/filter/option-delete",
    FILTER_UPDATE_OPTIONS: "v1/admin/filter/option-update",

    TYPE_CREATE: "v1/admin/college/type/create",
    TYPE_LIST: "v1/admin/college/type/list",
    TYPE_DELETE: "v1/admin/college/type/delete",
    TYPE_UPDATE: "v1/admin/college/type/update",

    COLLEGE_CREATE: "v1/admin/college/create",
    COLLEGE_LIST: "v1/admin/college/list",
    COLLEGE_CHECK_LIST: "v1/admin/college/list-college",
    COLLEGE_DELETE: "v1/admin/college/delete",
    COLLEGE_UPDATE_BASIC: "v1/admin/college/update-basic",
    COLLEGE_UPDATE_CONTENT: "v1/admin/college/update-content",

    COLLEGE_ADD_COURSE: "v1/admin/college/add-course",
    COLLEGE_LIST_COURSES: "v1/admin/college/list-courses",
    COLLEGE_DELETE_COURSE: "v1/admin/college/delete-course",
    COLLEGE_HIDE_COURSE: "v1/admin/college/hide-course",
    COLLEGE_UPDATE_COURSE: "v1/admin/college/update-course",
    COLLEGE_UPDATE_CATEGORIES: "v1/admin/college/update-categories",
    COLLEGE_UPDATE_IMAGE: "v1/admin/college/update-image",
    COLLEGE_UPDATE_SLUG: "v1/admin/college/update-slug",

    COLLEGE_LIST_NOTIFICATIONS: "v1/admin/college/list-notifications",
    COLLEGE_CREATE_NOTIFICATION: "v1/admin/college/save-notification",
    COLLEGE_DELETE_NOTIFICATION: "v1/admin/college/delete-notification",
    COLLEGE_UPDATE_NOTIFICATION: "v1/admin/college/update-notification",

    COLLEGE_CREATE_VIDEOLINK: "v1/admin/college/video-link",
    COLLEGE_DELETE_VIDEOLINK: "v1/admin/college/delete-video-link",
    COLLEGE_CREATE_VIDEOLINKS: "v1/admin/college/video-links",

    COLLEGE_UPLOAD_IMAGES: "v1/admin/college/upload-images",
    COLLEGE_UPLOADED_IMAGES: "v1/admin/college/images",
    COLLEGE_DELETE_IMAGE: "v1/admin/college/delete-image",
    LIST_CATEGORY_BY_COURSE: "v1/admin/college/categorybycourse",

    COLLEGES_REVIEW_LIST: "v1/admin/college/list-review",
    COLLEGES_REVIEW_UPDATE: "v1/admin/college/update-review",
    RANKING: "v1/admin/college/save-ranking",
    LIST_RANKING: "v1/admin/college/list-ranking",
    UPDATE_RANKING: "v1/admin/college/update-ranking",
    DELECT_RANKING: "v1/admin/college/delect-ranking",
    ADD_COLLEGE_RANKING: "v1/admin/college/add-college-ranking",
    LIST_COLLEGE_RANKING: "v1/admin/college/list-college-ranking",
    UPDATE_COLLEGE_RANKING: "v1/admin/college/update-college-ranking",
    DELECT_COLLEGE_RANKING: "v1/admin/college/delect-college-ranking",
    ADD_BANNAR: "v1/admin/college/add-bannar",
    LIST_BANNAR: "v1/admin/college/list-bannar",
    UPDATE_BANNAR: "v1/admin/college/update-bannar",
    LIST_BY_COLLEGES: "v1/admin/college/list-by-colleges",
    DELECT_BANNAR: "v1/admin/college/delect-bannar",
    LIST_COLLEGE_REVIEW: "v1/admin/college/list-college-review",
  },
  ADMISSION: {
    SETTINGS_MENU: "v1/admin/settings/menu-list",
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
  },

  SETTINGS: {
    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",

    LIST_USER_LOGS: "v1/admin/logs/list-all",
  },
  WEBSITE_CMS: {
    SAVE_PAGE: "v1/admin/cms/page/save",
    LIST_PAGES: "v1/admin/cms/page/list",
    DELETE_PAGE: "v1/admin/cms/page/delete",
    GET_PAGE: "v1/admin/cms/page/get",
    UPDATE_PAGE: "v1/admin/cms/page/update",
    GET_EVENT_CATEGORY: "v1/admin/cms/page/get-event-category",

    SAVE_GALLERY: "v1/admin/cms/page/save-gallery",
    LIST_GALLERIES: "v1/admin/cms/page/list-galleries",
    DELETE_GALLERY: "v1/admin/cms/page/delete-gallery",
    LIST_GALLERY: "v1/admin/cms/page/list-gallery",
    UPDATE_GALLERY: "v1/admin/cms/page/update-gallery",

    LIST_CATEGORY_NAMES: "v1/admin/cms/page/list-categories",

    FILES_LIST_DIRECTORY: "v1/admin/files/list-directory-v2",
    FILES_UPLOAD: "v1/admin/files/upload-files-v2",
    REMOVE_FILE: "v1/admin/files/remove-v2",
    CREATE_FOLDER: "v1/admin/files/create-folder-v2",
  },

  ENQUIRY: {
    LIST_ENQUIRY: "v1/admin/enquiry/list",
    UPDATE_ENQUIRY: "v1/admin/enquiry/update",
    ADD_ADVERTISEMENT: "v1/admin/enquiry/add-advertisment",
    DELECT_ADVERTISEMENT: "v1/admin/enquiry/delect-advertisment",
    EDIT_ADVERTISEMENT: "v1/admin/enquiry/edit-advertisment",
    LIST_ADVERTISEMENT: "v1/admin/enquiry/list-advertisment",
    LIST_APPLICATION_FORM: "v1/admin/enquiry/application-form-list",
    DELECT_SITE_ENQUIRY: "v1/admin/enquiry/delect-site-enquiry",
    DELECT_COURES_ENQUIRY: "v1/admin/enquiry/delect-enquiry",
    LIST_COURSEENQUIRY: "v1/admin/course/enquiry/list",
    LIST_ENQUIRY_COLLEGE_LIST: "v1/admin/course/enquiry/list-enqueriy-colleges",
    SAVE_CONTACT: "v1/admin/course/enquiry/save-contact",
    LIST_CONTACTS: "v1/admin/course/enquiry/list-contacts",
    DELECT_CONTACTS: "v1/admin/course/enquiry/delect-contacts",
    TRENDING_SERACH: "v1/admin/course/enquiry/create-trending-search",
    LIST_TRENDING_SEARCH: "v1/admin/course/enquiry/list-trending-search",
    DELECT_TRENDING_SEARCH: "v1/admin/course/enquiry/delect-trending-search",
    UPDATE_TRENDING: "v1/admin/course/enquiry/update-trending",
    COLLEGE_COURES_ENQUIRY_LIST: "v1/admin/college-course/enquiry/list",
  },
  DASHBOARD: {
    LIST_VISTER_COUNTER: "v1/admin/dashboard/list-vister-counter",
  },

  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },

  EXAMS: {
    EXAMS_CREATE: "v1/admin/exams/create",
    EXAMS_MENU_ADD: "v1/admin/exams/add-menu",
    FEATURED_EXAMS_ADD: "v1/admin/exams/add-featured-exam",
    LIST_MENU: "v1/admin/exams/list-menu",
    LIST_FEATURED_EXAM: "v1/admin/exams/list-featured-exam",
    UPDATE_MENU: "v1/admin/exams/update-menu",
    UPDATE_FEATURE_EXAM: "v1/admin/exams/update-feature-exam",
    DELETE_MENU: "v1/admin/exams/delect-menu",
    DELETE_FEATURE_EXAM: "v1/admin/exams/delect-feature-exam",
    ADD_SUB_MENU: "v1/admin/exams/add-submenu",
    LIST_SUB_MENU: "v1/admin/exams/list-sub-menu",
    EXAMS_UPDATE_BASIC: "v1/admin/exams/update-basic",
    EXAMS_LIST: "v1/admin/exams/exam-list",
    ALL_EXAMS_LIST: "v1/admin/exams/exam-list-all",
    EXAMS_UPDATE_CONTENT: "v1/admin/exams/update-list",
    EXAMS_DELETE: "v1/admin/exams/delect-exam",
    EXAM_UPDATE_IMAGE: "v1/admin/exams/update-exam-image",
    EXAM_NOTIFICATION: "v1/admin/exams/add-notification",
    LIST_EXAM_NOTIFICATION: "v1/admin/exams/list-notification",
    UPDATE_EXAM_NOTIFICATION: "v1/admin/exams/update-notification",
    DELECT_EXAM_NOTIFICATION: "v1/admin/exams/delect-notification",
  },
};
